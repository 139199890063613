.page-content {
    padding-left: 0em;
    padding-right: 0em;
}

.hidden {
    display: none;
}

.spacer {
    width: 15px;
}
